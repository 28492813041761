import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Link from "@/utils/link";

import PurpleDoorLogoHorizontal from "../Logo/PurpleDoorLogoHorizontal";
import HomeHeaderMenu from "./HomeHeaderMenu";

import { host } from "@/utils/config";

export const links = [
    { link: "/", label: "All" },
    { link: "/independent-living", label: "Independent" },
    { link: "/assisted-living", label: "Assisted" },
    { link: "/memory-care", label: "Memory" },
];

export const LinkItem = ({ link, label }) => {
    return (
        <div className="text-black font-bold uppercase hover:text-primary-400">
            <Link href={link}>
                <a>{label}</a>
            </Link>
        </div>
    );
};

const HomeHeader = ({ fixed }) => {
    return (
        <>
            <div
                id="purple-door-header"
                className={`z-50 w-full bg-gray-50 border-b-2 border-gray-100 shadow ${
                    fixed ? "fixed" : "static"
                }`}
            >
                <nav className="h-12 sm:h-16 flex justify-between items-center">
                    <div className="w-64 flex items-center">
                        <Link href="/">
                            <a className="-ml-10 sm:ml-2 scale-75 sm:scale-100">
                                <PurpleDoorLogoHorizontal />
                            </a>
                        </Link>
                    </div>

                    <div className="flex items-center sm:gap-2">
                        <div className="hidden sm:flex items-center gap-1">
                            {/*<Link href={"/about-us"}>
                                <a className="px-4 py-2 text-primary-500 font-bold  rounded hover:bg-gray-200">
                                    About Us
                                </a>
                            </Link>*/}

                            <Link href={"/resources"}>
                                <a className="px-4 py-2 text-primary-500 font-bold  rounded hover:bg-gray-200">
                                    Resources
                                </a>
                            </Link>

                            {/*<Link href={"/how-it-works"}>
                                <a className="px-4 py-2 text-primary-500 font-bold  rounded hover:bg-gray-200">
                                    How It Works
                                </a>
                            </Link>*/}

                            <Link href={host + "community-portal"}>
                                <a className="px-4 py-2 text-primary-500 font-bold  rounded hover:bg-gray-200">
                                    Manage My Listing
                                </a>
                            </Link>
                        </div>

                        <HomeHeaderMenu />
                    </div>
                </nav>
            </div>
        </>
    );
};

export default HomeHeader;
